//Import the global CSS varible and functions
@import "./../../../assets/style/GlobalCSSVariables.scss";
@import "./../../../assets/style/GlobalSCSSFunctions.scss";

/* ScoreChip.scss */

.score-chip {
  border: 1px solid $primaryColor !important;
  background: #fff !important;
}

.score-chip-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  border: 1px solid $primaryColor !important;
  background-color: #fff;
  border-radius: 4px;
}

.score-chip-label {
  font-weight: 600;
  margin-right: 8px;
  padding: 0 8px;
  color: $primaryColor;
  font-family: $robotoFont;
}

.score-chip-percentage {

  justify-content: space-between;
  border: 1px solid $primaryColor !important;
  font-weight: 600;
  width: 25%;
  padding: 8px 0;
  background: $primaryColor;
  text-align: center;
  color: #fff;
  font-family: $robotoFont;

  @include max_width_devices($mui_xs_breakpoint) {
    width: 40px;
  }
}

.borderedDivider-color {
  border-top: 1px solid #d4d4d4 !important;
  margin: 16px 0 0 0 !important;
}

.ScorecardPageHeading .MuiTypography-root {
  font-weight: 400;
  font-size: 18px;
  color: $primaryColor;
  display: flex;
  justify-content: center;
}

.MuiTypography-root.scorecardNameEmailInfo-font {
  color: #000000 !important;
  font-weight: 700 !important;
}

.MuiTypography-root.scorecardNameEmail-font {
  color: #000000 !important;
  font-weight: 400 !important;
}

.resumeContent-wrapper{
  font-family: $robotoFont;
}

.MuiChip-root {
  &.scorecardLabel-root {
    border-radius: 15px !important;
    font-weight: 700;
  }

  &.ErrorChip {
    color: #C83131;
    border-color: #C83131;
  }

  &.scoreSuccessChip {
    color: #22A73F;
    border-color: #22A73F;
  }
}

.keywords-background {
  background-color: #F8F8F8 !important;
  margin-bottom: 20px;
}

.keyword-margin {
  margin-left: 6px !important;
  padding-bottom: 20px;
}