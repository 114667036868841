@import "./../../../assets/style/GlobalCSSVariables.scss";
.main-header {
    background-color: #fff;

    .main-menu {
        button {
            color: #000;

            &:hover{
                color: #fff;
            }
        }
    }
    button, .MuiButton-text {
        margin-left: 5px;
        margin-right: 5px;
        text-transform: capitalize;
    }
    button, .MuiButton-contained {
        text-transform: capitalize;
    }
}
.user-option {
    .MuiMenu-list {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }
// recruiter statusbar css
  .cust-icon-reject{
    display: flex;
    align-items:center; 
    margin-right:3px;
  }
  .header-font{
    font-family: $robotoFont !important;
  }
  .custom-login-username span{
    font-family: $robotoFont !important;
  }
  