@import "../../../../assets/style/GlobalSCSSFunctions.scss";
@import "../../../../assets/style/GlobalCSSVariables.scss";

.custom-document-txt {
  color: $primaryColor;
  text-align: center !important;
  font-family: $robotoFont !important;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal !important;
}

.custom-resume-txt {
  color: $light_dark_black;
  font-family: $robotoFont !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.custom-txt {
  color: $light_dark_black;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.custom-btn {
  font-family: $robotoFont !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.custom-offerdetails-txt {
  color: $primaryColor;
  text-align: center !important;
  font-family: $robotoFont !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.custom-leftcontent {
  color: $light_dark_black;
  font-family: $robotoFont !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.custom-rghtcontent {
  color: $light_dark_black;
  font-family: $robotoFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-conversation-txt {
  color: $primaryColor;
  text-align: center !important;
  font-family: $robotoFont !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.custom-commentheading {
  color: $light_dark_black;
  font-family: $robotoFont !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.custom-posteddate {
  color: #8e8e8e;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.custom-cmnt-txt {
  color: $light_dark_black;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.AddCommentTxtArea .MuiInputBase-multiline {
  padding: 10px 10px;
  border: 1px solid #d7d7d7;
  background-color: #f9f9f9;
}

.custom-dialogbox {
  border-radius: 14px !important;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08) !important;
}

.custom-submit-btn {
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 6px !important;
  white-space: nowrap;
}

.custom-interview-thead th {
  color: $light_dark_black !important;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  padding: 8px 6px !important;
}

.custom-interview-tbody td {
  color: $light_dark_black !important;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 8px 6px !important;
}

.custom-reject-txt {
  color: $primaryColor;
  font-family: $robotoFont !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  padding-left: 12px !important;
  margin-bottom: 20px !important;
}

.custom-cnfrmreject-txt {
  color: $primaryColor;
  font-family: $robotoFont !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  padding-left: 12px !important;
  margin-bottom: 32px !important;
}
.custom-max-limit {
  color: $light_dark_black;
  font-family: $robotoFont !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.custom-popup-border .MuiPaper-rounded {
  border-radius: 14px;
}

.custom-preview-dwnload-size {
  width: 20px !important;
}

.custom-mobile-converstaion {
  @include max_width_devices($mui_md_breakpoint) {
    display: flex !important;
    justify-content: flex-end !important;
  }
}

.custom-contact-txt {
  color: $primaryColor !important;
  font-family: $robotoFont !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.custom-recruiterbox {
  color: $light_dark_black;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.custom-content {
  color: $light_dark_black;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.user-icon {
  margin-top: -3px;
}

.addCommentTextField .MuiFormHelperText-root{
  margin-left: 0px !important;
}
