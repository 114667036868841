@import './../../../assets/style/GlobalCSSVariables.scss';
@import './../../../assets/style/GlobalSCSSFunctions.scss';

.custom-details-txt {
  color: #353535 !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important; /* 120% */
}

.form-field-txt {
  color: #696969 !important;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 12px !important; /* 85.714% */
}

.cust-details-mr {
  margin-bottom: 32px;
}

.cust-upload-mr {
  margin-bottom: 24px !important;
}

.verifi-icon {
  display: flex;
  align-items: center;
}
.MuiGrid-item .cust-upload-mr {
  padding-top: 0px !important;
}

.verifi-success-icon{
    height: 16px!important;
    width: 16px!important;
    color: #26c296!important;
}

.padding-ac-details{
  margin-top: -20px !important;
}
