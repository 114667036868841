@import './../../../assets/style/GlobalCSSVariables.scss';
@import './../../../assets/style/GlobalSCSSFunctions.scss';


.candidateListTable{
    border-collapse: separate !important;
    border-spacing: 0 4px !important;
    background-color: #fafaff !important;
    border-top: 1px solid #D2D2F1 !important;
}

.candidateListTable th , .candidateListTable  td{
    padding: 8px !important;
}

.v2-table-row{
    background-color: white !important;
}

.hover-row:hover{
    background-color: #E2F0FF !important;
}

.v2-table-row td{
    border-bottom:none !important;    
}
.nameTableHead{
    min-width: 100px;
}

.phoneTableHead{
    min-width: 140px;
}

.dateTableHead{
    min-width: 180px;
}
.actionTableHead{
    min-width: 116px;
}

.emailCell, .phoneCell {
    display: flex;
    align-items: center;
}

.recruiterListActionButtonIcon{
    cursor: pointer !important;
    margin:0 5px 0 0;
    color: rgba(0, 0, 0, 0.87) !important;
}

.icon-span{
    display: inline-block;
}

.statusIcon{
    padding-right: 3px;
}

.Mui-selected.filterTabNav:hover{
    color: #fff !important;
}

.submissionIcon {
    font-size: 20px !important;
}

@media screen and (max-width: 1380px) and (min-width: 1268px) {
    .searchBarResWidth{
        width: 146px !important;
    }
  }

  @media screen and (max-width: 1440px) and (min-width: 1381px) {
    .searchBarResWidth{
        width: 250px !important;
    }
  }

@media screen and (max-width: 1414px) {
    .submissionIcon {
    margin-left: 4px !important;
    }
  }

  @media screen and (max-width: 1414px) {
    .submissionIcon {
    margin-left: 4px !important;
    }
  }


  @include max_width_devices($mui_md_breakpoint){
    .searchBarResWidth{
        width: 280px !important;
    }
  }

  @include max_width_devices($mui_sm_breakpoint){
    .searchBarResWidth{
        width: 280px !important;
    }
  }
 
.custom-candidatefilter-font{
    font-size: 18px !important;
}

.cust-recruiter-text{
    color:$primaryColor; 
  }
    