@import "./../../../../assets/style/GlobalCSSVariables.scss";
@import "./../../../../assets/style/GlobalSCSSFunctions.scss";

.rtrPopups-txt {
  text-align: center !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  font-size: 16px !important;
}
.clicktoBrowse {
  text-align: center !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  font-size: 12px !important;
}
.max-file-size-info {
  text-align: center !important;
}

.cancelConfirm-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  width: 100% !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.Uploadfile {
  border: 1px solid #11af22 !important;
  border-radius: 4px !important;
  padding: 9px 10px !important;
  background: #f8f8ff !important;
  max-width: 355px !important;
  margin: 0 auto !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.deletefileFontSizes{
  font-family: $robotoFont !important;
}
.confirmation-messag{
  font-family: $robotoFont !important;
}

