@import "./../../assets/style/GlobalCSSVariables.scss";
@import "./../../assets/style/GlobalSCSSFunctions.scss";

.recruitment-Service-txt {
  color: $primaryColor !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
}

.primary-color-txt {
  color: $primaryColor !important;
}

.txt-roboto-font {
  font-family: $robotoFont !important;
}

.txt-fontSize {
  font-size: 20px !important;
}

.txt-fontweight {
  font-weight: 300;
}

.btnFont-size {
  font-size: 14px !important;
}

.takeyour-txt-heading {
  font-weight: 700 !important;
  font-size: 32px !important;
}
.fontweignt-size-digit {
  font-weight: 700 !important;
  font-size: 20px !important;
  font-family: $robotoFont !important;
}
.tab-font {
  font-size: 16px;
  font-family: $robotoFont !important;
}
