@import "../../../assets/style/GlobalCSSVariables.scss";
@import "../../../assets/style/GlobalSCSSFunctions.scss";

.custom-pwd-head {
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.custom-enterpwd-txt {
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  padding-top: 20px;
}

.custom-set-email {
  color: #838594;
  font-family: $robotoFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-setpwd-line {
  color: #353535 !important;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding-top: 40px;
}

.custom-setpwd-button {
  color: #fff !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  width: 163px !important;
  border-radius: 6px !important;
  background: #4540db;
}

.custom-pwdset-txt {
  color: #353535;
  text-align: center;
  font-family: $robotoFont !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: normal;
}
.custom-pwdset-msg {
  color: #353535;
  text-align: center !important;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.custom-pwdset-btn {
  color: #fff !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.custom-padding-img {
  padding-top: 32px !important;
}

.custom-container-padding {
  padding: 0px 84px 40px;
}

.custom-container-rspnv {
  @include max_width_devices($mui_md_breakpoint) {
    padding: 0px 25px 40px;
  }
}

.custom-btm-img {
  position: fixed;
  width: 500px;
  top: 0;
  right: 0;
  z-index: -1;
}

.custom-top-img {
  position: fixed;
  width: 500px;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.custom-displaynone {
  @include max_width_devices($mui_md_breakpoint) {
    display: none;
  }
}
