//Import the global CSS varible and functions
@import './../../../assets/style/GlobalCSSVariables.scss';
@import './../../../assets/style/GlobalSCSSFunctions.scss';

.page-mt{
  margin-top: 72px;
}

.phoneCountryCustom {margin-top: 1px;width: 77%;}
.asterisk_input::after {
  content:" *"; 
  color: #e32;
  position: absolute; 
  margin: 0px 0px 0px -20px;}
.phoneCountryCustom input {
  border: none !important;
  border-bottom: 1px solid !important;
  width: 134% !important;
  border-radius: 0px !important;
  background: transparent !important;
}

.phoneCountryCustom .flag-dropdown {border: none !important;background: transparent !important;border-bottom: none !important;}
.signupcomplete-autocomplete{
  width: 100%;
}
.phoneCountryCustom-adminForm {
  margin-top: -5px;
  width: 77%;
}
.phoneCountryCustom-clientForm{
  margin-top: -2px !important;
}
.phoneCountryCustom-adminForm input {border: none !important;border-bottom: 1px solid !important;width: 134% !important;border-radius: 0px !important;}

.phoneCountryCustom-adminForm .flag-dropdown {border: none !important;background: transparent !important;border-bottom: none !important;}
.center {
    margin: auto;
    width: 50%;
    border: 3px solid blue;
    padding: 10px;
    text-align: center;

  }
  input.pw {
    -webkit-text-security: disc;
}
@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}
/* make input field look like password without giving input type=password */
input.key {
  font-family: 'password', sans-serif !important;
}

.borderedPhoneInputContactUs{
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding-right: 21px;
  flex-direction: row;
  width: 30%;

}

.react-tel-inputContactUs .form-controlContactUs {
  position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    /* padding-left: 48px; */
    margin-left: 0;
    background: transparent;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 100%;
    width: 191% ;
    outline: none;
    
    
}

.react-tel-inputContactUs {
  height: 56px;
    font-family: 'Roboto',sans-serif;
    font-size: 15px;
    position: relative;
    width: 100%;
    display: block;
    flex-direction: row;
}

.ContactUsRow{
  align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 81%;
}

.react-tel-inputContactUs .form-controlContactUs.invalid-number {
  border: 1px solid #f44336
}

.react-tel-inputContactUs .form-controlContactUs.invalid-number:focus {
  box-shadow: 0 0 0 1px #f44336
}

.react-tel-inputContactUs .form-controlContactUs.invalid-number+div:before {
  content: 'Error';
  display: none;
  color: #f44336;
  width: 27px
}

.completeSignUpRightBanner{
  background-color: $light-blue-bgcolor !important;
  text-align: left !important;
  padding: 20px !important;

  @include min_width_devices($mui_lg_breakpoint){
    margin-top: 15px;
  }
}

.completeSignForm{

  @include max_width_devices($mui_sm_breakpoint){
    padding: 0px !important;
  }
}