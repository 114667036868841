@import './../../../../../assets/style/GlobalCSSVariables.scss';
@import './../../../../../assets/style/GlobalSCSSFunctions.scss';

.custom-client-popup .MuiDialog-container .MuiPaper-root.MuiPaper-elevation{
    border-radius: 14px !important;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08) !important;
}
.custom-popup-txt{
    font-family: $robotoFont !important;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: #4540DB;
    line-height: normal;
}
.custom-popup-txt1{
    text-align: center;
    font-family: $robotoFont !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: normal;
    padding: 30px;
}
.custom-popup-txt2{ 
    cursor: pointer;
    color: #4540DB;
    font-family: $robotoFont !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-align: center;
}
.custom-call-txt{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.custom-call-txt-btn{
    color: #FFF !important;
    font-family: $robotoFont !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 24px !important;
    padding: 8px 24px !important;
}
.custom-submit-otp{
    border-radius: 6px;
    padding: 8px 24px;
    color: #FFF;
    font-family: $robotoFont !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}
.custom-success-clr{
    color: rgba(38, 194, 150, 1) !important;
}
.custom-reject-clr{
    color: rgba(248, 25, 25, 1) !important;
}