@import '../../assets/style/GlobalCSSVariables.scss';
@import '../../assets/style/GlobalSCSSFunctions.scss';

.advanceSearchPaper {
    position: absolute;
    z-index: 3;
    max-width: 600px;
    margin-right: 15px;
}
.advanceSearchPaper.custom-advancesearch-top{
    top: 46px;
    left: 9px;
}
.advanceSearchWrapper{
    padding: 20px 15px 20px 20px;
}

.advanceSearchToggleActive{
    background-color: #4540db !important;
    color: #fff;
}

.advanceSearchToggleActive svg {
    color: #fff;
}

.AdvanceSearchLabel {
    font-size: 16px;
    color: #4540DB;
    font-weight: 400;
    margin: 7px 0 0 0 !important;
    font-family:  $robotoFont !important;
}

.newAdvanceSearchLabel {
    margin: 7px 0 0 0 !important;
    color: $light_dark_black !important;
    font-family: $robotoFont !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.advanceSearchBtn{
    font-size: 16px !important
}
.advSearchBtn{
    height: 2.2rem;
    padding: 10px 15px !important;
    font-family: $robotoFont !important;
}

/* .AdvanceSearchCurrencySelect .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
    min-height: 1.8375em;
} */

.payrate-label{
    margin-top: 15px !important;
}

.closeArea{
    text-align: right;
    position: absolute;
    right: 0;
    padding: 0px 14px 0 0;
    top: 12px;
}

.close-Btn:hover{
    background-color: rgba(0, 0, 0, 0.04) !important;
    color: rgba(0, 0, 0, 0.54);
}

.autocomplete-advanceSearch-location .autocomplete-dropdown-container{
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    z-index: 2;
    width: -webkit-fill-available;
    position: absolute !important;
    margin-right: 23px;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
}

.suggession-box-advs {
    padding: 10px 20px;
}

.suggession-box-advs:hover {
    background: #eeeeee45;
}

.add-close-btn{
background-color: rgb(137 137 137 / 4%) !important;
margin-left: 5px !important;
}


.MuiAutocomplete-option{
    font-size: 16px;
    font-weight: 400;
    font-family: "Abel", sans-serif !important;
}

.payrate-inputs {
    padding-top: 8px !important;
}
.all-jobs{
    padding-top: 23px !important;
}
.saved-jobs{
    padding-top: 22px !important;
}

.sort-Job{
    width:16px !important;
    position: relative !important;
    top: 10px !important;
    color: #4540db !important;
    font-weight:600 !important;
    font-size: 0.875 !important;

}
.custom-sort-Job{
    width:16px !important;
    color: #4540db !important;
    font-weight:600 !important;
    font-size: 0.875 !important;

}
.custom-arrw{
    position: none!important;
    top:0!important;
}
.custom-arrw-btn{
    display: flex;
    align-items: center;
    flex-direction: row;
}

/* all jobs table css */
.table-all-jobs{
    margin-top: 20px!important;
}
/* .search-allJobs{
    justify-content:flex-end ;
    display: flex;
} */

.job-title-row{
    width: 100px;
}