
@import './../../../assets/style/GlobalCSSVariables.scss';

.manageCLientButton {
    font-family: $robotoFont !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
    margin-right: 16px !important;
    padding: 8px 20px;
}

.phoneCountryCustom-adminForm {
    width: 74% !important;
}

.formSectionHeading{
    color: $light_dark_black !important;
    font-family: $robotoFont !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}

// change history table css

.TableHeading{
    color: $light_dark_black;
    font-family: $robotoFont !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}
span.modifyDateText {
    white-space: nowrap;
    color: #787878;
}

.noWordWrap{
    white-space: nowrap;
}

span.createdByNameText{
    color: $light_dark_black;
}

.jobTypeCheckboxItem, .taxtermsCheckboxItem {
    padding: 4px 8px 4px 0 !important;
}

.clientChangeTrackerListTable{
    border-collapse: separate !important;
    border-spacing: 0 4px !important;
    background-color: #fafaff !important;
    border-top: 1px solid #D2D2F1 !important;
}

.clientChangeTrackerListTable th , .clientChangeTrackerListTable  td{
    padding: 8px !important;
}

.v2-table-row{
    background-color: white !important;
}

.hover-row:hover{
    background-color: #E2F0FF !important;
}

.v2-table-row td{
    border-bottom:none !important;    
}

.startIcon p{
    color: #000;
}

.Mui-disabled p{
    color: rgba(0, 0, 0, 0.6) !important
}