@import "../../../assets/style/GlobalCSSVariables.scss";
@import "../../../assets/style/GlobalSCSSFunctions.scss";


.UploadResume{
    min-width: 280px
}
.skillLabelRecuiter{
    display: inline-block;
}

.max-skills-text{
    transform-origin: top right;
    float: right;
}
@media screen and (max-width: 1200px) {
    .wide {max-Width: 23%!important;

}

}
@media screen and (max-width: 1441px) {
    .wide {
        max-Width: 23%!important;
    }

}
@media screen and (max-width: 767px) {
    .wide {max-Width: 100%!important;        
    }

    .UploadResume{
        min-width: 100% !important
    }

}
/* @media screen and (min-width: 900px) {
.linkd{
    width: 60%!important;
}
.linkd1{
    width:100%!important;
}
} */
.editRecruter-wt{
    width: 100% !important;
}