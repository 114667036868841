@import "./../../../assets/style/GlobalCSSVariables.scss";
@import "./../../../assets/style/GlobalSCSSFunctions.scss";

.CustErrorMsg {
  color: #d32f2f;
  font-family: $robotoFont !important;
}

.jobCurrencyDropdown {
  margin-top: 25px;
}

.postingJobFormHeading {
  color: $light_dark_black !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-bottom: 0 !important;
}

.postingJobButton {
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
}

.PageWrapper {
  background-color: $pageBackgroundColor !important;
}
.PageTitle {
  color: $light_dark_black !important;
  font-family: $robotoFont !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important  ;
}

.borderedDivider {
  border-top: 1px solid $borderColor !important;
  margin: 16px 0 !important;
}
.cust-field-style {
  margin: 8px 0 !important;
}

.cust-field-style-switch {
  margin: 8px 12px !important;
}

.selectedChipOutlined {
  background-color: $primaryColor;
  color: #fff;
}

.custom-appbreadcrumb {
  font-size: 14px !important;
  font-family: $robotoFont !important ;
  font-weight: 600 !important;
}

.form-field-font{
  font-family: $robotoFont !important ;
}
