@import "./../../../assets/style/GlobalCSSVariables.scss";
@import "./../../../assets/style/GlobalSCSSFunctions.scss";
.phone-verify-btn {
  text-align: center;
}

.phone-verify-dialog {
  text-align: unset;
}

.titlePopupText {
  color: $primaryColor !important;
  text-align: center;
  font-family: $robotoFont !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  margin-bottom: 24px;
}

.subTitlePopupText {
  color: $subTitleColor !important;
  text-align: center;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  margin-bottom: 24px;
}

.selectNumberControl {
  margin-bottom: 16px;
}

.radioBtnLabelText {
  color: $labelGray !important;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 12px;
}

.custom-margin-callbtn {

  margin-right: 34px !important;

  @include max_width_devices($mui_md_breakpoint) {
    margin-right: 14px !important;
  }
}
.custom-verify-mobile {
  @include max_width_devices($mui_md_breakpoint) {
    padding: 30px 10px !important;
  }
}
