@import './../../../assets/style/GlobalCSSVariables.scss';
@import './../../../assets/style/GlobalSCSSFunctions.scss';

.profile-btns {
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  padding: 15px;
  cursor: pointer;
}

.profile-btns.selected {
  font-weight: 600; /* Change to the desired font-weight for selected items */
  background-color: white; /* Change to the desired background color for selected items */
}

.profile-btns.selected2 {
  font-weight: 600; /* Change to the desired font-weight for selected items */
  font-size: 24px;
  padding: 10px;
}