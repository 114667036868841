
@import './../../../assets/style/GlobalCSSVariables.scss';
@import './../../../assets/style/GlobalSCSSFunctions.scss';

.MuiStep-alternativeLabel {
    flex: 1;
    position: relative;
    width: 25% !important;
    padding: 0px;
}

.v2-popupTitle{
    font-family: $robotoFont !important;
    text-align: center;
    font-size: 32px !important;
    font-weight: 700 !important;
    color: $primaryColor !important;
    line-height: normal;
}

.v2-popupContentText{
    text-align: center;
    font-family: $robotoFont !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: normal;
    padding: 16px;
}

.popupActionBar{
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    width: 100%;
    margin-top: 8px;
}

.clientSignUpPageHeading{
    color: $light_dark_black !important;
    text-align: center !important;
    font-family: $robotoFont !important;
    font-size: 28px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 2 !important;

    @include max_width_devices($mui_md_breakpoint){
        margin-top: 10px;
        margin-bottom: 5px;
    }
}

.clientSignUpPageHeadingSubTitle{
    color: $light_dark_black !important;
    text-align: center !important;
    font-family: $robotoFont;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    margin-bottom: 16px !important;
}

.customTypographyHeading{
    color: $light_dark_black !important;
    font-family: $robotoFont !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important; 
}

.customTypographyHeadingSubTitle{
    color: $light_dark_black !important;
    font-family: $robotoFont !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18px !important;
}

.customStepLabel .MuiStepLabel-label{
    color: $light_dark_black;
    text-align: center;
    font-family:  $robotoFont !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

 .MuiStepLabel-active, .MuiStepIcon-active, .MuiStepIcon-completed, .MuiStepLabel-completed{
    color: $primaryColor !important;
}

.clientSubmitBtn{
    background-color: $primaryColor;
    color: #FFF;
    font-family: $robotoFont !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500;
    line-height: 24px; /* 120% */
}

.clientSubmitBtn .MuiButton-label, .backBtn .MuiButton-label{
    font-family: $robotoFont !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500;
    line-height: 24px; /* 120% */
}

img.clientUserRoadmap {
    margin-left: -2px;
}

.signUpFormHeading{
    color: $light_dark_black;
    text-align: left;
    font-family:  $robotoFont !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

// input fiels css start

// password field
.passwordToggler{
    color: $primaryColor !important;
    padding: 4px !important;
    margin-right: -4px !important;
}

.passwordToggler:hover {
    color: #fff !important;
}
// end 

.phoneNumberLabel{
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
}

.additionInfoFieldLabel{
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
}

.consentLinkLabel{
    color:#4F4F4F;
    font-family: $robotoFont !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.consentLinkLabel a{
    color: #2401FD;
    font-family: $robotoFont !important;
}

.consentCheck{
    display: flex;
    align-items: start;
}

.consentCheck > span {
    padding-top: 0px;
}

.AdditionInfoTxtArea .MuiFormHelperText-sizeMedium{
    color: $error_text_color !important;
    margin-left: 0 !important;
}

.AdditionInfoTxtArea .MuiInputBase-multiline {
    padding: 10px 10px;
}

.phone-verify-btn {
    margin-top: 20px;
    margin-left: 15px;
}

.custEmail {
    margin-top: -4px;
}

// uplaod logo css start
.LogoUploaderWrapper{
    font-family: $robotoFont;
    border: 2px dashed $success_color;
    padding: 13px;
    text-align: center;
    cursor: pointer;
}

.deletePreviewImage{
    padding: 8px !important;
}

.browser-text{
    color: $success_color;
}

.drag-drop-text{
    font-size: 16px;
    font-weight: 500;
    font-family: $robotoFont !important;
    color: $light_dark_black;
}
//upload logo css end here

.step-nav-button-stack{
    margin-top: 20px;
}

// custom btn css start

.custom-button {    
    display: inline-block;
    padding: 7px 20px;
    background-color: $primaryColor;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: $primaryColor;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  }
  
.custom-button:active {
    background-color: $primaryColor; 
  }
  
  .custom-button span {
    font-family: $robotoFont !important;
    display: inline-block;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500;
    line-height: 16px;
  }
//custom btn css end

.StepHasError .MuiStepIcon-root {
    color: $mandate_color !important;
}

.StepHasError .MuiStepLabel-alternativeLabel{
    color: $mandate_color !important;
}

.step-wrapper label{
    display: flex !important;
    flex-direction: column !important;
}

.step-wrapper .MuiFormControlLabel-label{
    color: $light_dark_black;
    text-align: center;
    font-family:  $robotoFont !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.MuiStepConnector-alternativeLabel {
    top: 20px !important;
    margin-left: -23px;
    margin-right: 42px !important;
    position: relative;
}

.stepCompletedmarkIcon{
    color: $primaryColor !important;
    margin: 9px 0;
}

.activeStep .MuiFormControlLabel-label{
    color: $primaryColor !important;
}

#contract-div {
    height: 75vh;
}