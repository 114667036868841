.verify-dg-head-text{
    color: #4F4F4F !important;
    letter-spacing: 2px !important;
    font-size: 20px !important;
    font-weight: 400 !important;
}

.verify-dg-desc-text {
    font-size: 18px !important;
    font-weight: 400 !important;
    padding: 0 20px;
}
.das-recruiter{
    padding-top: 20px !important;
}

.email-link{
    text-decoration: none !important;
    color:#000000 !important;
}
 /* verify recruiter success dialog */
.verify-rec-text{
    font-size: 18px !important;
    font-weight: 400 !important;
    padding: 0px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}
.font-wt-rec{
    font-weight: 600 !important;
}
.Verify-rec-pop-txt{
   padding: 0px !important;
}

.success-icon{
    width: 40px;
}
.verify-success-cross{
font-size: 15px !important;
}

.verifyRecruiterIcon {
    color: limegreen;
    height: 16px !important;
    width: 16px !important;
  }