//Import the global CSS varible and functions
@import './../../assets/style/GlobalCSSVariables.scss';
@import './../../assets/style/GlobalSCSSFunctions.scss';

@media screen and (max-width: 479px) { 
    .btn-wrapper{
       flex-direction: column;
       label{
        margin: 0 0 20px;
       }
       button{
        margin: 20px 0 0 0;
        width: 152px;
       }
    }
}

.autocomplete-root{
    .MuiFormControl-root{
        width: 100% !important;
    }
    display: inline-block;
}
.recruiter-location-address{
    width: 100%;
}
.candidate-address{
    width: 100%;
}
.submit-resume-address{
    width: 100%;
}
.tool-tech-wrapper pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}
.revise-score-wrapper{
    display: flex;
    align-items: center;
}
.revise-score-wrapper p{
    width: calc(100% - 320px);
}
.btn-wrapper{
    display:flex;
    justify-content: space-between;
    margin-left: 8px;
}
@media screen and (max-width: 767px) {
    .revise-score-wrapper{
        flex-direction: column !important;
        align-items: flex-start;
    }
    .revise-score-wrapper p{
        width: 100%;
        padding-bottom: 30px;
    }
    .btn-wrapper{
        width: 100%;
        justify-content: flex-end;
    }
    
}
@media screen and (max-width: 479px) { 
    .btn-wrapper{
       flex-direction: column;
       label{
        margin: 0 0 20px;
       }
       button{
        margin: 20px 0 0 0;
        width: 152px;
       }
    }
}

.ScoreResumeCardHeading{
    padding: 16px 16px 0 16px !important;
}

.ScoreResumeCardContent{
    padding: 8px 8px 8px 16px !important;
}

.recruiterListSearchbarField input{
    padding: 10px !important;
}

.d-flex-align-center{
    align-items: center;
    display: flex;
}

.left-space {
    margin-left: 8px !important
}

.topHeadingRecruiterList {
    padding-top: 0px !important;
}

.recruiterListSearchIcon {
    padding: 4px !important;
}

.v2-score-label {
    font-family: $robotoFont !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.v2-score-subhead {
    font-family: $robotoFont !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

@media screen and (max-width: 479px) { 
    .subLabel{
        width: 225px;
        display: ruby;
    }
    .FormControl-label> div{
        margin-top: 30px;
    }
}

.totalexperiencemargin {
    margin-left: 6px;

    @include max_width_devices($mui_md_breakpoint) {
        margin-left: 0px;
    }
}