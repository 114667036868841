.submissionFilterTable th{
    color:#4540DB;
    font-size: 18px;
    font-weight: 400;
    font-family: "Abel", sans-serif;
}


.custom-just-contnt{
    justify-content: space-between!important;
    margin-left: 0!important;
    width: 100%!important;
}
.custom-width{
    padding: 16px 16px 16px 0!important;
}

.cust-font-jobDetails{
    font-size: 14px;
}