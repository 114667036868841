@import "../../assets/style/GlobalCSSVariables.scss";
@import "../../assets/style/GlobalSCSSFunctions.scss";

.custom-incomplete-txt {
  color: #4540db;
  text-align: center !important;
  font-family: $robotoFont !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.custom-tablehead th {
  color: #353535;
  font-family: $robotoFont;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.custom-tablebody td {
  color: #353535;
  font-family: $robotoFont;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.addbasicdetails {
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}


.form-field-txt label {
  color: #696969 !important;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 12px !important;
}

.addrecruiterpadding {
  margin-left: 8px;
  background-color: #FFF !important;
  margin-top: 20px;
  margin-right: 20px;
  padding: 20px;
}

@include max_width_devices($mui_md_breakpoint){
  .addrecruiterpadding {
    padding: 2px;
  }
}