@import '/src/assets/style/GlobalCSSVariables.scss';
@import "/src/assets/style/GlobalSCSSFunctions.scss";

.dialog-blur {
    backdrop-filter: blur(5px);
}

.text-heading-signup {
    color: #4540DB !important;
    font-size: 28px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: normal !important;
    font-family: $robotoFont !important;
}

.text-subheading-signup {
    color: #1D343E !important;
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    font-family: $robotoFont !important;
}

.grid-subheading-signup {
    margin-top: 8px !important;
}

.grid-email-signup {
    margin-top: 24px !important;
}

.grid-social-signup {
    margin-top: 24px !important;
}

.item-recruiter-signup {
    border-radius: 20px !important;
    border: 1px solid #ECECEC !important;
    padding: 16px 24px !important;
}

.item-client-signup {
    border-radius: 20px !important;
    border: 1px solid #ECECEC !important;
    padding: 16px 24px !important;
    margin-top: 16px !important;
}

.item-selected {
    border-radius: 20px !important;
    border: 1px solid #4540DB !important;
    background: #FFF !important;
}

.text-email-signup {
    color: #1D343E !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: normal !important;
    margin-left: 16px !important;
    margin-right: 64px !important;
    flex-grow: 1 !important;
    font-family: $robotoFont !important;

    @include max_width_devices($mui_md_breakpoint) {
        font-size: 16px !important;
        margin-left: 7px !important;
        margin-right: 0px !important;
    }
}

.item-verification-btn {
    margin-top: 16px !important;
}

.btn-verify {
    font-size: 16px !important;
    text-transform: capitalize;
    font-family: $robotoFont !important;
    font-weight: 400 !important;
}

.item-social-divider {
    margin-bottom: 16px !important;
}

.form-email-signup {
    margin-top: 16px !important;
}

.text-email-label {
    color: #1D343E !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.input-email-signup {
    font-size: 14px !important;
    padding: 8px !important;
}

.text-error-msg {
    font-size: 14px !important;
}

.icon-signup-option {
    font-size: 40px !important;
}

.icon-signup-check {
    font-size: 18px !important;
}

.text-social-divider {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: normal !important;
    font-family: $robotoFont !important;
}