@import "../../../assets/style/GlobalCSSVariables.scss";
@import "../../../assets/style/GlobalSCSSFunctions.scss";

.custom-client-job {
  color: #353535 !important;
  font-family: $robotoFont !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.custom-heading-clientjobdetail {
  color: #696969 !important;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
}

.custom-txt-clientjobdetails {
  color: #353535 !important;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.custom-chip {
  background-color: rgba(226, 237, 255, 1) !important;
  border-radius: 50px !important;
  margin-bottom: 10px !important;
  font-size: 16px !important;
}

.custom-workauth-heading {
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  padding-bottom: 20px;
}

.custom-rightsidebar {
  border-radius: 8px !important;
  background: #efefff !important;
  padding: 12px 32px !important;
  font-family: $robotoFont !important;
}

.custom-description-heading {
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.custom-heading-summary {
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.custom-button-Robo {
  font-family: $robotoFont !important;
  border-right: 1px solid #d9d9d9;
}


.custom-text-btn {
  background-color: white;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.custom-list .custom-rightsidebar {
  display: flex;
  flex-direction: column;
}

.custom-list .custom-rightsidebar span {
  display: flex;
  align-content: flex-start;
  width: 100%;
}

.custom-list .custom-rightsidebar span:nth-child(2) {
  font-weight: 700;
  font-size: 24px;
}

.custom-list .custom-rightsidebar .MuiListItem-root {
  display: flex;
  flex-direction: column;
}

.custom-list .custom-rightsidebar .MuiListItem-root span:nth-child(2) {
  font-weight: 700;
  font-size: 24px;
}


.custom-activity-paddingtop {
  border-top: 1px solid #d9d9d9;
  padding-top: 40px !important;
}

.custom-activity-chip .custom-noborder {
  border-right: 0 !important;
}

.custom-paadingtop {
  padding-top: 32px !important;
}

.custom-jobDesc {
  padding-right: 32px;
}

.custom-border-top {
  border-top: 1px solid #d9d9d9;
}

.custom-border-bottom {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 30px;
}

.custom-padding-bottom {
  padding-bottom: 40px;
}

.custom-active-color {
  color: #26af89;
}

.custom-three-button {
  font-size: 16px !important;

  @include max_width_devices($mui_md_breakpoint) {
    width: 100%;
    margin-top: 10px !important;
  }
}

.custom-button-right {
  margin-right: 16px !important;
}

.custom-chiptxt-color {
  color: #353535 !important;
  font-size: 16px !important;
}

.custom-alrt-margin {
  margin-top: 10px;
}

.custom-clientjobdetail-padding {

  padding-left: 32px !important;
  padding-right: 32px !important;

  @include max_width_devices($mui_sm_breakpoint) {
    padding-left: 10px !important;
    padding-right: 5px !important;
  }
}

.custom-clientjobdetail-chip {

  padding-left: 26px !important;

  @include max_width_devices($mui_sm_breakpoint) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.featured-icon-mr {
  margin-left: 5px !important;
}

.custom-all-pages .custom-activity-chip .MuiChip-outlined {

  border-right: 1px solid #e7e7e7;
  border-radius: 0 !important;
  padding-right: 77px;

  @include max_width_devices($mui_md_breakpoint) {
    border-right: none !important;
    padding-right: 0px !important;
  }
}

.custom-activity-chip {

  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e7e7e7;
  margin-right: 10px;
  
  @include max_width_devices($mui_md_breakpoint) {
    margin-right: 0px;
  }
}

.sharebutton {
  @include min_width_devices($mui_md_breakpoint) {
    display: flex !important;
    justify-content: flex-end !important;
  }
}

.rightSidebarbtn{
  text-align: right;
}


.saveUnsaveMainWrapper .MuiDialog-paper{
  padding: 16px 12px;
}

.saveUnsaveMainWrapper .MuiDialogActions-root .MuiButton-root{
  text-transform: inherit !important;
}

.saveUnsaveMainWrapper .MuiButton-containedPrimary{
  background: $primaryColor !important;
}
.chip-notActive{
  margin-left: 10px;
}

.marginRightPaper {
  margin-right: 44px;

  @include max_width_devices($mui_md_breakpoint) {
    margin-right: 16px;
  }
}