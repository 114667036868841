@import "../../assets/style/GlobalCSSVariables.scss";
@import "../../assets/style/GlobalSCSSFunctions.scss";


.contarct-tab:hover{
color: #fff !important;
}

.custom-breadcrumb-contract {
  color: $primaryColor;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}


.custom-candidatedetails-txt {

  color: $light_dark_black;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-left: 35px !important;

  @include max_width_devices($mui_md_breakpoint) {
    margin-left: 8px !important;
    margin-bottom: 5px !important;
  }
}

.custom-name-txt {
  color: $labelGray !important;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
}

.custom-previous-rate {
  font-family: $robotoFont !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.custom-project-txt {
  color: $light_dark_black;
  font-family: $robotoFont;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

.custom-project-id {
  color: $light_dark_black;
  font-family: $robotoFont;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
}

//Contracts

.custom-contracttable-th th {
  color: $light_dark_black;
  font-family: $robotoFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-tab-txt {
  color: $light_dark_black !important;
  font-family: $robotoFont !important;
  font-size: 21px !important;
  line-height: normal !important;
}

.custom-contract-tablebody {
  color: $light_dark_black;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.custom-contract-detailsbtn {
  color: $primaryColor;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}

.custom-contract-value {
  color: #8d8d8d;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.custom-contractloc-listinclr {
  color: #353535 !important;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.custom-contract-listinclr {
  color: #828282 !important;
  font-family: $robotoFont !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.custom-contractdetail-count {
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 21px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.custom-tabs-fontweight {
  font-weight: 400 !important;

  &.Mui-selected {
    font-weight: 600 !important;
  }
}

.custom-status-padding {
  text-align: right;
  @include max_width_devices($mui_md_breakpoint) {
    text-align: left !important;
    padding-left: 22px !important;
    margin-bottom: 20px !important;
  }
}