.notfound {
  font-weight: 400 !important;
  font-size: 29px !important;
  line-height: 137.5% !important;
  display: flex;
  padding: 24px 16px !important;
  text-align: center;
  width: calc(100%);
  justify-content: center;
  margin: auto;
}
.image-txt {
  margin: auto;
  display: flex;
  flex-direction: column !important;
  align-items: center;
}
.image-txt img {
  text-align: center;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  width: calc(100% - 80px) !important;
}
.image-txts {
  /* width: 75% !important; */
  padding: 0px !important;
}
@media screen and (max-width: 480px) {
  .image-txt img {
    width: 100%;
    padding: 0px !important;
  }

  .notfound{
    width: calc(100% - 10px );
  }
}

@media screen and (max-width: 990px) {
  .image-txt img {
    padding: 0px !important;
  }

  .notfound{
    width: calc(100% - 40px );
  }
}
