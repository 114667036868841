.customCity {
    margin-top: 0px !important;
}
.customRelocate {
    margin-top: 20px !important;
}
.custom-prview-dwn-icon{
    font-size: 20px !important;
}
.custom-dwnload-prview-icon{
    font-size: 20px !important;
}
.custom-candidate-filter-align{
    display: flex !important;
    justify-content: space-between !important;
}
.custom-candidate-sortby-right{
    width: 100% !important;
    justify-content: right;
    display: flex !important;
    align-items: center !important;
}
.custom-search-bar-candidate{
    form{
    width: 70% !important;
    }
}