@import "./../../../assets/style/GlobalCSSVariables.scss";
@import "./../../../assets/style/GlobalSCSSFunctions.scss";

.custom-checkbox-alg .MuiCheckbox-root.Mui-disabled{
  color: rgba(0, 0, 0, 0.26) !important;
}

.moveToPendingWrapper{
  margin: 8px 0 !important;
}

.clientCollapsibleSearchBar {
  margin-right: 0px !important;
  top: 38px !important;
  left: 0 !important;
}

.span.AdvanceSearchBtnWrapper {
  display: inline-block;
}

.joblistingSearchbar {
  display: inline-block !important;
  margin-left: 0 !important;
  position: relative;
}
.custom-Client-fontsizes th {
  font-size: 16px !important;
  font-family: $robotoFont !important ;
  font-weight: 500 !important;
  color: #353535 !important;
}
.custom-Client-fontsizes div {
  font-size: 16px !important;
  font-family: $robotoFont !important ;
  font-weight: 500 !important;
  color: #353535 !important;
}
.custom-Btn-fontsizes {
  font-size: 16px !important;
  font-family: $robotoFont !important ;
  font-weight: 500 !important;
  color: #353535 !important;

  &:hover {
    color: #e2f0ff !important;
  }
}
.disable-custom-Btn-fontsizes {
  font-size: 16px !important;
  font-family: $robotoFont !important ;
  font-weight: 500 !important;
  color: #00000042 !important;
}
.custom-breadCum-fontsize {
  font-size: 14px !important;
  font-family: $robotoFont !important ;
  font-weight: 600 !important;
}

.Domain-txt-fontwt {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: $robotoFont !important;
}
.custom-header-menus {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.custom-grid-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.edit-verify-clr {
  color: #353535 !important;
}

.custom-width-search {
  /* WIDTH: 90%; */
  margin-right: 14px;
}

.cust-btn-add-Recruiter {
  padding: 0 13px !important;
}
.custom-addJob {
  display: flex;
  justify-content: flex-end;
  font-family:'mulish', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.custom-grid-2 .custom-width-search {
  width: 383px;
  padding: 0 !important;
  margin: 0;
  position: relative;
}

.jobs-search-box input {
  background: transparent !important;
  padding-right: 20px;
}

.custom-grid-2 .custom-width-search .jobs-search-box {
  margin: 0 !important;
}
.custom-fontsize-status1 {
  padding: 3px 5px !important;
}
/* status css */
.Client-listingTable-wrapper .statusChip {
  font-size: 14px;
  font-weight: 400;
  border-radius: 30px !important
    ;
  height: 24px;
}
.Client-listingTable-wrapper .statusChip.Approved {
  color: #317337;
  background-color: #dcffdf;
}
.Client-listingTable-wrapper .statusChip.Rejected {
  color: #9d2323;
  background-color: #fcdcdc;
}
.Client-listingTable-wrapper .statusChip.Suspended {
  color: #8b3927;
  background-color: #fff2ef;
}
.Client-listingTable-wrapper .statusChip.Pending {
  color: #994425;
  background-color: #fcddc7;
}
.Client-listingTable-wrapper .statusChip.Incomplete {
  color: #4c0a6b;
  background-color: #f6eaff;
}
/* filter search status all btn   */
.custom-btn-space-clinet div {
  padding: 5px 10px !important;
  margin: 0 10px !important;
}
.custom-table-box {
  padding: 0 !important;
  margin: 0 !important;
}
.custom-btn-space-clinet {
  justify-content: flex-end !important;
  max-width: calc(66% - 8px) !important;
  padding: 0;
  margin: 0 !important;
  height: 55px;
  padding-right: 5px;
}

/* css */
.MuiPaper-root.Client-table {
  .MuiTable-root {
    .MuiTableCell-root {
      padding: 10px;

      &:first-child {
        padding-left: 20px;
      }
    }
  }
}

.custom-hover-bgcolor:hover {
  background-color: #e2f0ff !important;
}
.custom-hover-bgcolor td {
  vertical-align: top !important;
  font-size: 14px !important;
  font-family: $robotoFont !important;
}
/* .custom-Client-fontsizes tr {
    max-width: 100wv;
}

.custom-Client-fontsizes tr th, td{
   
} */


.custom-other-col-width {
  min-width: 100px !important;
  max-width: 140px !important;
  width: 140px;
}

/* client listing backgroud color */
.clientlist-grid {
  background-color: #fafaff;
  padding-left: 10px;
}
.cust-btn-bgClr {
  background-color: #e1e1f6 !important;
}
.cust-btn-bgClrDetails {
  background-color: #e8f2ff !important;
}
.submit-btn-ht {
  height: 28px;
  padding: 6px 16px 6px 16px;
  width: 76px;
}

.searchbarRow-ht-btn {
  height: 36px !important;
}
.custom-table-gapping {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.minWidthROw1 {
  min-width: 50px !important;
  max-width: 80px;
  width: 80px;
}
.minWidthROw {
  min-width: 100px !important;
  max-width: 180px;
  width: 130px;
}
.custom-align-left {
  text-align: left !important;
}

.custom-client-companyname {
  font-family: $robotoFont !important;
}

.phNumber span {
  font-family: $robotoFont !important;
}

.custom-client-status span {
  font-family: $robotoFont !important;
}

.cust-subDetails-btn {
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.custom-checkbox-algn {
  display: flex;
  width: 100%;
  justify-content: center;
}
.custom-updown-arrow {
  display: flex;
  flex-direction: row;
  width: 135px !important;
  min-width: 90px;
  max-width: 140px;
  justify-content: center;
}

.custom-check span {
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.adv-search-btn {
  color: $primaryColor !important;
  border-radius: 2px !important;
  border-color: #dfdfe9 !important;
  font-weight: 500 !important;
  font-family: $robotoFont !important;
  background-color: #ffffff !important;
  height: 37px !important;
}

.custom-fontize-hdr label span {
  font-size: 16px;
  font-weight: 400;
  font-family: $robotoFont !important;
}

.custom-fontize-hdr label .MuiSvgIcon-root {
  height: 20px;
  width: 20px;
}
.custom-col-width {
  min-width: 100px !important;
  max-width: 194px !important;
  width: 194px;
  word-break: unset !important;
  white-space: unset !important;
}
// client Table css
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.show-x {
  overflow-x: unset !important;
}

.custom-grid-rspnsv {
  @include max_width_devices($mui_sm_breakpoint) {
    display: flex !important;
    justify-content: flex-start !important;
    margin-left: -10px;
    flex-wrap: wrap;
  }
}

.custom-advancesearch-rspnv {
  @include max_width_devices($mui_sm_breakpoint) {
    max-width: 100% !important;
  }
}

.custom-btn-rspnsv {
  @include max_width_devices($mui_sm_breakpoint) {
    min-width: 120px !important;
    margin-left: 8px !important;
  }
}

.custom-advance-search {
  position: relative;
}

.custom-searchbar-respnv {
  @include max_width_devices($mui_sm_breakpoint) {
    margin-top: 9px;
  }
}

.custom-btnmargin-rspnsv {
  @include max_width_devices($mui_sm_breakpoint) {
    margin-left: -7px !important;
  }
}

.contact-Btn{
font-style: normal;
line-height: normal;
padding: 8px 40px !important;
}
.Recruiters-font{
color: #353535!important;
font-family: $robotoFont !important;
font-size: 14px!important;
font-style: normal!important;
font-weight: 400!important;
line-height: normal!important;
}

.custom-payrate-font{
  color: #353535!important;
  font-family: $robotoFont !important;
  font-size: 14px!important;
  font-style: normal!important;
  font-weight: 400!important;
  line-height: normal!important;
}

.setalert-font{
  font-family: $robotoFont !important;
}

.locationfont{
  font-size: 12px !important;
  color: $greyColor !important;
}

.setalert-font:hover{
  color: #fff;
}

.setalert:hover{
  background-color: $primaryColor !important;
}

.setalert-text:hover p{
  color: #fff !important;
}

.setalert-disabled {
  color: #00000042 !important;
}
.jobAlertToggleButton{
  border-radius:4px;
  background: #f4f5f8;
  padding: 6px 0px 7px 9px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  height: 24px;
  justify-content: center;
  min-width: 100px
}