
span.mandatory-mark {
    color: red;
}

.OfferDetailsPanel {
    padding: 30px !important;
}

.OfferDetailsPanel .MuiGrid-item{
    margin-bottom: 15px !important;
}

.OfferDetailsPanel .abel{
    font-size: 1rem !important;
}

.candiOfferDetialsHeading {
    margin-left: 25px;
    font-size: 2rem;
}