@import './../../../assets/style/GlobalCSSVariables.scss';
@import './../../../assets/style/GlobalSCSSFunctions.scss';

.text-heading {
    padding-top: 0px !important;
    color: $light_dark_black !important;
    font-family: $robotoFont !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.btn-cancel {
    color: $light_dark_black !important;
    font-family: $robotoFont !important;
    font-style: normal !important;
    font-weight: 500 !important;

    &:hover {
        color: #fff !important;
    }
}