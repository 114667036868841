@import './../../../assets/style/GlobalCSSVariables.scss';
@import './../../../assets/style/GlobalSCSSFunctions.scss';

.candidateInterviewDatePicker {
    margin-top: 6px !important;
}

.interviewAVdescription p{
    margin-left: 0px !important;
}
.custom-flex-start{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.custom-flex-wrapper{
    display: flex;
    align-items: flex-start;
    .MuiChip-outlined{
        outline: none;
        box-shadow: none;
        border: 1px solid transparent;
    }
}
.border-right{
    border-right:1px solid #929292;
    min-width: 80%;
}
.right-side-wrapper{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.SubmissionTable {
    padding-top: 16px !important;
}

@media only screen and (max-width: 575px) {
    .custom-flex-start{
        flex-direction: column;
        align-items: flex-start;
    }
    .button-wrapper{
        width: 100%;
        text-align: right;
        .MuiButton-sizeLarge{
            margin: 20px 0 !important;
        }
    }
    .custom-flex-wrapper{
        flex-direction: column;
        .border-right{
            border-right:1px solid transparent;
            min-width: 100%;
        }
        .right-side-wrapper{
            border-top: 1px solid #929292;
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    
 }

 @media only screen and (max-width: 479px) {
    .tabs-header-buttons .MuiTabs-flexContainer{
        flex-direction: column;
    }
    .custom-flex-wrapper{
        .right-side-wrapper{
            flex-direction: column;
        }
    }
    .flex-col-mobile{
        flex-direction: column;
        align-items: flex-start ;
        > div{
            width: 100%;
            &:nth-child(2){
                text-align: right;
                justify-content: flex-end;
            }
        }
    }
    
 }
.submission-action-btn{
    margin-top: 5px !important;
    font-family: $robotoFont !important;
    margin-right: 10px !important;
    font-size: 14px !important;
}
.searchIcon-img:hover{
    filter: brightness(0) invert(1);
}
.myRowHover:hover { 
    background: rgb(69 64 219) !important ;
    
    
       
       
          &:hover {
            .MuiTableCell-root,
            a,
            .MuiTypography-root {
              color: #fff !important;
            }
            .submissionList-button{
                background-color: #E0E3FA;
                color:#929292;
                &:hover {
                    background-color: #E0E3FA;
                    color:#929292;
                }
            }
           
          }
        
       
      
    
}

.btnActionTD{
    vertical-align: top !important;
    padding: 0px !important;
    @include max_width_devices($mui_lg_breakpoint){
        padding: 8px !important;
    }
}

.btn-filter{
    margin: 0px 10px 10px 0 !important;
}

.submissioon-searchToolbar .jobs-search-box{
    height: 38px;
}
.submissionList-button{
    background-color: blue($color: #000000);
    color:white;
    width: 130px;
    font-family: $robotoFont !important;
   
    @include min_width_devices($mui_xs_breakpoint) {
        width: 120px;
      }

      @include min_width_devices($custom_breakpoint_1300) {
        width: 130px;
      }
    
    @include min_width_devices($custom_breakpoint_1400) {
        margin-left: 0px !important;
      }
    
   }
   
.fontmulish-bread{
    font-family:'mulish', sans-serif !important;
    text-decoration: none;
    &.submission-color{
        color:#4540DB
    }
}

.fontRoboto-bread{
    font-family:$robotoFont !important;
    text-decoration: none;
    &.submission-color{
        color:#4540DB
    }
}

@media only screen and (max-width: 1200px) {
    .expandBtnAction-col {
        padding-left: 0px !important;
    }
}
.custom-preview-size{
    width:16px !important;
}

.offerDetailsHeading{
    display: flex;
    padding-left: 100px !important;

    @include max_width_devices($mui_sm_breakpoint){
        padding-left: 50px !important;
    }

    @include max_width_devices($custom_breakpoint_480){
        padding-left: 25px !important;
    }

    @include max_width_devices($custom_breakpoint_320){
        padding-left: 20px !important;
    }
}
.custom-sub-ht{
    margin-top: -10px;
}
.custom-sub-rec-ht{
    margin-bottom:18px !important;
    margin-top:7px !important;
}