@import './GlobalCSSVariables.scss';

// We define the mixins for major breakpoints here
 
 // $breakpoint is the variable that can have several values
 // eg., tablet

 /*************************************************
 apply media query function with minimum screen sizes
 @parama breakpoint in px unit
 ***************************************************/
@mixin min_width_devices ($breakpoint) { 
  
    @media only screen and (min-width: $breakpoint) {
      @content;
    }
}


 /*************************************************
 apply media query function with maximum screen sizes
 @parama breakpoint in px unit
 ***************************************************/

@mixin max_width_devices ($breakpoint) { 
  
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

 /*************************************************
 apply media query function with minimum and maximum screen sizes
 @parama breakpoint in px unit
 ***************************************************/

 @mixin max_min_width_devices ($max_breakpoint,$min_breakpoint) { 
  
  @media only screen and (max-width: $max_breakpoint) and (min-width: $min_breakpoint)  {
    @content;
  }
}

/*********************************************************

Apply the custom heading style for h4 type heading

@param for customTypography
font family
font style 
font waight
font size
line height
text color
text align 

*********************************************************/

// customTypographyHeading4
@mixin customTypography(
    $fontFamily : $robotoFont,
    $fontStyle : normal, 
    $fontWeight : 600, 
    $fontSize : 18px, 
    $lineHeight : 20px, 
    $textColor : $light_dark_black,
    $textAlign : left
  ) 
 {
  font-family: $fontFamily !important;
  font-style: $fontStyle !important;
  font-weight: $fontWeight !important;
  font-size: $fontSize !important;
  line-height: $lineHeight !important;
  color: $textColor !important;
  text-align: $textAlign;
  
}

/*********************************************************/