//Global SCSS varible declaration

$abelFont: "Abel", sans-serif;
$robotoFont: "Roboto", "Helvetica", "Arial", sans-serif;
$normalFontColor: #000;
$primaryColor: #4540db;
$primaryColorLight: #8c83fa;
$primaryColorLight2: #c2c0ff;
$secondaryColor: #f9f9ff;
$greyColor: #929292;
$light-blue-bgcolor:#E6E9FB;
$myFontSize: 18px;
$myWidth: 680px;
$leftNavText: #fff;
$whiteColor:"#fff";
$border-radius: 5px;
$light-blue-color: #f6f7fc;
$small: 300px;
$medium: 900px;
$disabledColor:#00000042;
$error_text_color:#ff0000;
$success_color:#26C296;
$snackbar_success:#0C4A39;
$snackbar_bg:#C4E9DE;
$mandate_color : #f44336;
$subTitleColor:#1D343E;
$labelGray:#696969; 
// new design color scheme

$light_dark_black:#353535;
$light_gray_bg_color:#F9FAFF;
$borderColor:#D2D2F1;
$pageBackgroundColor:#fafaff;
$hoverRowColor:#E2F0FF;
// Small devices (landscape phones, 576px and up)
$small_min : 576px;

// Medium devices (large phone, 768px and up)
$large_mobile_min : 768px;

// Large devices (tablet, 992px and up)
$tablet_min : 992px;

// Extra large devices (tablet, desktops, 1200px and up)
$dekstop_min : 1200px;
 
// laptop devices 
$laptops_min: 1366px;

// High-res laptop or desktop
$extra_Large_min : 1920px;

//MUI break points for media query

$mui_xs_breakpoint:0px;
$mui_sm_breakpoint:600px;
$mui_md_breakpoint:900px;
$mui_lg_breakpoint:1200px;
$mui_xl_breakpoint:1536px;

//Custom Break Point for media query

$custom_breakpoint_320:320px;
$custom_breakpoint_480:480px;
$custom_breakpoint_1300:1300px;
$custom_breakpoint_1400 : 1400px;