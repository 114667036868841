@import './../../../assets/style/GlobalCSSVariables.scss';

.chipProps{
    border-radius: 30px !important;
    font-size: 14px !important;
    padding: 4px 0 !important;
}
.successChip{
    color: #317337 !important;
    background-color: #DCFFDF !important;
}

.rejectedChip{
    color: #9D2323 !important;
    background-color: #FCDCDC !important;
}

.pendingChip{
    color: #994425 !important;
    background-color: #FCDDC7 !important;
}

.suspendedChip{
    color: #8B3927 !important;
    background-color: #FFF2EF !important;
}

.markInCompleteChip{
    color: #4C0A6B !important;
    background-color: #F6EAFF !important;
}

.backlistedChip{
    color: #2E2E2E;
    background-color: #EEEEEE;
}