@import "./../../assets/style/GlobalCSSVariables.scss";
@import "./../../assets/style/GlobalSCSSFunctions.scss";

.team-name {
    color: #353535 !important;
    font-family: $robotoFont !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.teamMemberGridList{
    height: 80vh;
    overflow-y: auto;
    padding-right: 12px;
}