.viewstyle{
    color: #2e7d32 !important;
}
.toviewstyle{
    color: #666666 !important;
}

.viewSubmission-work-athorization-th{
    min-width: 105px;
}

.viewSubmission-resume-th{
    min-width: 40px;
}

.viewSubmission-scorecard-th{
    min-width: 60px;
}

.MuiPaper-root.submission-table .MuiTable-root .MuiTableCell-root:first-child{
    padding-left: 10px;
}

.submissiontableAll th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-j1pa56-MuiTableCell-root {
    padding: 7px 3px !important;
}

.submissiontableAll td{
    font-size: 0.775rem !important;
    /* padding: 10px 5px !important; */
}

.MuiPaper-root.submission-table .MuiTable-root .MuiTableCell-root {
    padding: 10px 5px;
}

.MuiPaper-root.submission-table .MuiTable-root .MuiTableCell-root:last-child {
    padding-right: 0px !important;
}

.viewSubmission-currentLocation-th{
    min-width: 80px;
}

.viewSubmission-status-th{
    min-width: 90px;
}

.viewSubmission-TotalExp-th{
    min-width: 55px;
}
.view-sub{
    padding-top: 16px;
}
.Recruiter-sub{
    padding-top: 23px;
}