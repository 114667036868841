@import './../../../assets/style/GlobalCSSVariables.scss';
@import './../../../assets/style/GlobalSCSSFunctions.scss';

.custom-location-txt {
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.custom-address-txt {
  color: #696969;
  font-family: $robotoFont !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
}

.MuiGrid-item .custom-padding-top {
  padding-top: 0px !important;
}

.MuiGrid-item .custom-userprfl-top {
  margin-top: -28px !important;
}

.roboto-font {
  font-family: $robotoFont !important;
}
