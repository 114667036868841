@import './../../../../assets/style/GlobalCSSVariables.scss';
@import './../../../../assets/style/GlobalSCSSFunctions.scss';

/* new css */
.custom-rec-details{
    color: $primaryColor !important;
    text-align: center;
    font-family: $robotoFont !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal;
    }
    
    .cust-rec-info{
    color: #1D343E !important;
    text-align: center!important;
    font-family: $robotoFont!important;
    font-size: 16px!important;
    font-style: normal!important;
    font-weight: 400!important;
    line-height: normal;
    }
    .cutom-col-name{
    color: #696969!important;
    font-family: $robotoFont!important;
    font-size: 14px!important;
    font-style: normal!important;
    font-weight: 400!important;
    line-height: 12px!important; /* 85.714% */
    }
    
    .cutom-field-input{
    color: #8D8D8D !important;
    font-family: $robotoFont !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    margin-top: 8px !important; /* 125% */
    
    }
    .cutom-phone-input{
    color: #353535 !important ;
    font-family: $robotoFont !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important; /* 125% */
    }
    .viewDetails-Btn{
    color: #FFF !important;
    font-family: $robotoFont !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important; /* 120% */
    }
    .cust-txt-field-mt{
        margin-top: 16px !important;
    }

    .custom-paper-clr{
        background-color: #FFF !important;
    }

    .checkCircle-icon{
        color:#04C875 ;
        font-size:16px !important  ;
        align-items: center;
        margin-left: 5px;
    }
    .viewdetails-btn{
        padding-bottom: 24px;
        padding-top: 16px;
    }

    .stc-btn{
        margin-bottom: 8px !important;
    }

    .stc-btnRow{
        text-align: right;
    }

    @media only screen and (max-width: 600px) {
        .stc-btnRow{
            text-align: center;
    }
    }