.react-multiple-carousel__arrow--left {
    left: 0px;
  }
 
  .react-multiple-carousel__arrow--right {
    right: 0px;
  }
  .arrowleft{
    background-color: blue;
  }
  /* .arrowleft:hover{
    background-color: blue;
  } */
  /* .arrowright{
    background-color: rgba(0,0,0,0.5);
  } */