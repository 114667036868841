@import "./../../../assets/style/GlobalCSSVariables.scss";


.popup-heading-text{
  font-family: $robotoFont !important;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: $primaryColor;
  line-height: normal;
}

.feebackCommentInpWrapper{
  padding: 16px 0;
}
.v2-EmailVerifyPopupTitle {
  font-family: $robotoFont !important;
  text-align: center;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: $primaryColor !important;
  line-height: normal;
}

.pendingFeebackComment .MuiFormHelperText-root{
  margin-left: 0px !important
}

.commentCntLabel{
  text-align: right;
  font-size: 0.75rem;
  font-family: $robotoFont !important;
  color: rgba(0, 0, 0, 0.6);
}

.v2-primary-button {
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.v2-outlined-button {
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.button-gap{
    margin-right: 16px !important;
}

.minWidth110{
  min-width: 110px !important;
}
