@import "./../../../assets/style/GlobalCSSVariables.scss";
@import "./../../../assets/style/GlobalSCSSFunctions.scss";

.custom-Client-fontsizes th{
  font-size: 16px!important;
  font-family: $robotoFont !important ;
  font-weight: 500!important;
  color: #353535!important;
}
.custom-Client-fontsizes div{
  font-size: 16px!important;
  font-family: $robotoFont !important ;
  font-weight: 500!important;
  color: #353535!important;
}
.custom-Btn-fontsizes{
  font-size: 16px!important;
  font-family: $robotoFont !important ;
  font-weight: 500!important;
  color: #353535!important;

  &:hover{ color: #E2F0FF !important;

  }
}
.disable-custom-Btn-fontsizes{
  font-size: 16px!important;
  font-family:$robotoFont !important ;
  font-weight: 500!important;
  color: #00000042 !important;
}
.custom-breadCum-fontsize{
  font-size: 14px!important;
  font-family:$robotoFont !important ;
  font-weight: 600!important;
}

.custom-header-menus {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.custom-grid-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.edit-verify-clr{
  color: #353535!important;
}

.custom-width-search {
  /* WIDTH: 90%; */
  margin-right: 14px;
}

.cust-btn-add-Recruiter {
  padding: 0 13px !important;
}
.custom-addbtns {
  width: 100px;
  display: flex;
  justify-content: flex-end;
  font-family:$robotoFont!important ;
  font-weight: 500!important;
  font-size: 16px!important;
}
.custom-grid-2 .custom-width-search {
  width: 383px;
  padding: 0 !important;
  margin: 0;
  position: relative;
}

.jobs-search-box input{
  background: transparent !important;
  padding-right: 20px;
  padding-left: 6px;
}

.custom-grid-2 .custom-width-search .jobs-search-box {
  margin: 0 !important;
}
.custom-fontsize-status1 {
  padding: 3px 5px !important;
}
/* status css */
.Client-listingTable-wrapper .statusChip {
  font-size: 14px;
  font-weight: 400;
  border-radius: 30px !important
    ;
  height: 24px;
}
.Client-listingTable-wrapper .statusChip.Active {
  color: #317337;
  background-color: #dcffdf;
}
.Client-listingTable-wrapper .statusChip.Inactive {
  color: #9d2323;
  background-color: #fcdcdc;
}
.Client-listingTable-wrapper .statusChip.Suspended {
  color: #8b3927;
  background-color: #fff2ef;
}
.Client-listingTable-wrapper .statusChip.Pending {
  color: #994425;
  background-color: #fcddc7;
}
.Client-listingTable-wrapper .statusChip.Incomplete {
  color: #4c0a6b;
  background-color: #f6eaff;
}
/* filter search status all btn   */
.custom-btn-space-clinet div {
  padding: 5px 10px !important;
  margin: 0 10px !important;
}
.custom-table-box {
  padding: 0 !important;
  margin: 0 !important;
}
.custom-btn-space-clinet {
  justify-content: flex-end !important;
  max-width: calc(66% - 8px) !important;
  padding: 0;
  margin: 0 !important;
  height: 55px;
  padding-right: 5px;
}

/* css */
.MuiPaper-root.Client-table {
  .MuiTable-root {
    .MuiTableCell-root {
      padding: 10px;

      &:first-child {
        padding-left: 20px;
      }
    }
  }
}

.custom-hover-bgcolor:hover {
  background-color: #E2F0FF!important;
}
.custom-hover-bgcolor td {
  font-size: 14px!important;
  font-family: $robotoFont !important;
}
/* .custom-Client-fontsizes tr {
    max-width: 100wv;
}

.custom-Client-fontsizes tr th, td{
   
} */

.custom-col-width{
  min-width: 100px!important;
  max-width: 194px!important;
  width: 194px;
}
.custom-other-col-width{
  min-width: 100px!important;
  max-width: 140px!important;
  width: 140px;
}

/* client listing backgroud color */
.clientlist-grid{
  background-color: #FAFAFF;
  padding-left: 10px;
}
.cust-btn-bgClr{
  background-color: #E1E1F6!important;
}
.searchbarRow-ht-btn{
  height: 36px!important;
}
.custom-table-gapping{
  border-collapse: separate;
  border-spacing: 0 15px;
}
.minWidthROw1{
  min-width: 50px!important;
  MAX-WIDTH: 80PX;
    WIDTH: 80PX;
}
.minWidthROw{
  min-width: 100px!IMPORTANT;
    MAX-WIDTH: 180PX;
    WIDTH: 130PX;
}
.custom-align-left{
  text-align: left!important;
}

.custom-client-companyname{
  font-family: $robotoFont !important;
}

.phNumber span{
  font-family: $robotoFont !important;
}

.custom-client-status span{
  font-family: $robotoFont !important;
}

.cust-field-style-switch{
  margin: 8px 12px !important;
}

.switch-v2-small{
  width: 29px !important;
  height: 16px !important;
  .MuiSwitch-thumb{
    width: 7px !important;
    height: 7px !important;
  }
  .MuiSwitch-switchBase {
    transform: translateX(-5px) !important;
  }
  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(5px) !important;
  }
}

.toolbarActionBtns {
  margin: 0 4px 8px 8px !important;
  padding: 6px 8px !important;
}

.ClientListingSearchbarContainer .search-icon {
  margin-right: 8px;
}

.ClientListingSearchbarContainer .jobs-search-box input {
  padding-left: 8px !important;
}

.searchbarRow-ht-btn span {
  display: inherit;
 margin: 0px!important;
}

.closeIcon {
  font-size: 20px !important;
}

.custom-name-minwidth {
  @include max_width_devices($mui_md_breakpoint) {
    min-width: 136px !important;
  }
}

.custom-status-width{
  min-width: 80px !important;
  max-width: 100px !important;
  width: 90px !important;
}

.custom-adminlist-padding{
  padding-top: 4px !important;
}