@import './../../assets/style/GlobalCSSVariables.scss';
@import './../../assets/style/GlobalSCSSFunctions.scss';

.cancelBtn{
    color: #353535 !important;
}

.cancelBtn:hover{
    color: #fff !important;
}

.edit-heading{
    color: #4540DB;
    font-family: $robotoFont !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
}

.teamMemberFormPage{
    height: 60vh;
}