.recruiterInfoBox{
    width: 550px !important;
    margin: 0 auto !important;
}

.viewDetailsBtn{
    margin: -10px 20px 15px 0px;
}

.message-icon{
    height:"15.29px";
    width:"20px";
}

.telephone-icon{
    height:"14.17px";
    width:"16.67px";
}

.telPhone-Data{
    margin-left: 5px !important;
    font-size: 14px;
}
.eyes-icon{
   width:  16.29px !important;
   height: 11px !important;
}
.download-icon{
    height: 16px !important;
    width: 16.29px !important;
    
}
.pointer-align{
    margin-top: -5px;
}