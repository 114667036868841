@import './../../assets/style/GlobalCSSVariables.scss';
@import './../../assets/style/GlobalSCSSFunctions.scss';

.btn-ht{
height: 38px;
}
.contractSectionHeading{
  color: $primaryColor;
  font-weight: bold;
  font-size: 1.2rem !important;
  display: block;
  padding-bottom: 12px;
}

.earningListPage-btns{
  margin-right: 10px !important;

  @include max_min_width_devices("1152px","601px"){
    margin-bottom: 8px !important;
  }
}
.date-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    background: #fff;
    border: 1px solid rgb(223, 223, 233);
    border-radius: 4px;
    @media screen and (max-width: 1379px) {
      padding: 6px;
    }
    @media screen and (max-width: 600px) {
        margin-bottom: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        } 
        @media screen and (max-width: 480px) {
            flex-direction: column;
            width: 100%;
            
            } 
  }
  .filter-btn{
    margin-left: 5px !important;
    @media screen and (max-width: 1379px) {
      width: 100px !important;
    } 
  }
  .custom-date-picker {
    display: flex;
    align-items: flex-start;
    padding: 0 10px;
    padding-top: 2px;
    @media screen and (max-width: 480px) {
        width: 100% !important;
        margin: 10px 0;
      }
      
      .MuiTextField-root{
        @media screen and (max-width: 480px) {
            width: 80% !important;
          }
         
      }
    input{
      height: auto;
      line-height: normal;
      font-size: 14px;
    
    }
    label{
      transform: translate(14px, 10px) scale(1);
      &.Mui-focused{
        transform: translate(14px, -10px) scale(1);
        font-size: 12px;
      }
      &.MuiFormLabel-filled{
        transform: translate(14px, -10px) scale(1);
        font-size: 12px;
      }
    }
    svg{
      width: 16px;
      height:16px;
    }
  
  } 
  .date-wrapper-main{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    padding-left:37px;
    @media screen and (max-width: 1199px) {
        padding: 0;
        justify-content: flex-end;
       } 
       @media screen and (max-width: 575px) {
       flex-direction: column;
    align-items: flex-end;
       } 
  }
  .main-wrapper-styles{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    @media screen and (max-width: 1199px) {
       flex-direction: column ;
      } 
  }
  .search-input{
    width: 60% !important;
    @media screen and (max-width: 1199px) {
        width: 100% !important;
        margin-bottom: 20px !important;
       }
  }
  .earning-heading-text{
    font-weight: 400;
    width: 246px;
    height:26px;
    color: $primaryColor;
    font-size: 18px !important;
}

.payPeriodText{
  min-width: 160px;
  display: inline-block;
}

.commissionDueDateText{
  white-space: nowrap;
  min-width: 80px;
  display: inline-block;
}

.adjustment-row{
  background:#eded98;
}

.contractFilter-wrapper{
  min-width: 153px;
}

.earning-tbl-td-commissionHead{
  min-width: 90px;
}

.ApproveActionBox{
  display: flex;
  justify-content: start;
  margin-bottom: 15px;
}

.approvedEarningCancelBtn{
  margin-right: 15px !important;
} 

.timeSheetInvoiceSection{
  margin-top: 20px;
}

.adminearning-table-compnent {
  max-width: 100%;
  box-shadow: none !important;
  th{
    font-size: 16px;
    padding: 8px 4px;
    line-height: 1.5;
  }
  td{
    color: $normalFontColor;
    font-size: 12px;
    padding: 8px 4px;
    line-height: 1.5;
  }
}

.JrDatePicker{

.MuiIconButton-edgeEnd{
  padding: 5px;
  margin-left: 4px;
}

input{
  font-size: 12px;
}
}

.EarningCurrencyFilter ul:first-of-type{
  padding: 8px;
}

.EarningCurrencyFilter ul{
   background-color: transparent !important;
}

.UpdateActionBox {
  padding: 20px 0 0 0;
}

.projectId{
  display: block;
  white-space:nowrap;
}

.projectName{
  white-space: pre-wrap;
}

.earningDetailsField input {
  font-size: 12px;
}

.sumCommisions{
  color: $primaryColor;
}

.durationText{

  @media screen and (max-width: 480px) {
    padding: 10px 10px 0 10px;
  }
}

.durationLabel{
  font-family: $robotoFont;
  padding-right: 8px;
  padding-top: 3px;
  font-size: 14px;
  @media screen and (max-width: 480px) {
    width: 30px;
  }

}

.date-filterWrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @include max_width_devices($mui_sm_breakpoint){
    flex-direction: column;
  }
}

.FilterWrapper{
  padding-left: 10px;

  @include max_width_devices($mui_sm_breakpoint){
    margin-top: 15px;
    padding-left: 0px;
  }
}