@import "./../../../assets//style/GlobalCSSVariables.scss";
@import "./../../../assets/style/GlobalSCSSFunctions.scss";

.cust-fontsizes tr th {
  font-size: 16px !important;
}

.pTermsText {
  margin-top: 15px;
  color: #000;
}

.recruiterNameLabel {
  margin-top: 15px !important;
}

// all compensation individual css
.allcompensationTable {
  border-collapse: separate !important;
  border-spacing: 0 4px !important;
  background-color: #fafaff !important;
  border-top: 1px solid #d2d2f1 !important;
}
.allcompensationTable th,
.allcompensationTable td {
  padding: 10px !important;
}

.allcompensation-fontsizes th {
  font-size: 16px !important;
  font-family: $robotoFont !important ;
  font-weight: 500 !important;
  color: #353535 !important;
}

.roboto-font-sizes {
  font-family: $robotoFont !important ;
  font-size: 14px !important;
}

.changeTrckerfilterCheckbox{
 padding: 0px !important;
 margin-left: 20px !important;
}