@import "./../../assets/style/GlobalCSSVariables.scss";
@import "./../../assets/style/GlobalSCSSFunctions.scss";

.lets-have-txt {
  color: $primaryColor !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
}

.form-text-font {
  font-family: $robotoFont !important;
  margin-top: 25px !important;
}

.text-font-family {
  font-family: $robotoFont !important;
}

.aboutusfontweight {
  color: #000;
  font-weight: 600 !important;
}