.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.grecaptcha-badge {
  width: 56px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}

@media (max-width:900px){
  .grecaptcha-badge {
    width: 65px !important;
  }
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
.customCity{
  width: 100% !important;
}
.customState{
  width: 15% !important;
  margin-top: 0px !important;
}
 @media (min-width:360px)  { 
 .autocomplete-root{
  width: 100% !important;
} 
 }

@media (min-width:600px) {
  .autocomplete-root{
  width: 30% !important;
}
} 

 @media (min-width:360px)  { 
 .experienceInput{
  width: 100% !important;
} 
 }

@media (min-width:600px) {
  .experienceInput{
  width: 30% !important;
}
} 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
