@import '../../../assets/style/GlobalCSSVariables.scss';
@import '../../../assets/style/GlobalSCSSFunctions.scss';
.select_dom {
    font-size: 16px !important;
   font-family: $robotoFont !important;
   font-weight: 700 !important;
   color: rgba(53, 53, 53, 1) !important;
   margin-right: 6px;
}
.status-checkbox {
    font-size: 16px !important;
   font-family: $robotoFont !important;
   font-weight: 400 !important;
   color: rgba(53, 53, 53, 1) !important;
}
.roboto-btn{
    font-family: $robotoFont !important;
}
.select_duration {
    font-size: 15px !important;
   font-family: $robotoFont !important;
   font-weight: 400 !important;
   width: 127px !important;
   height: 20px !important;
}
.select_user_filter {
    font-size: 16px !important;
   font-family: $robotoFont !important;
   font-weight: 500 !important;
   width: 127px !important;
   height: 20px !important;
}
.submission-tblData{
   
        font-size: 14px !important;
       font-family: $robotoFont !important;
       font-weight: 400 !important;
       color: rgba(53, 53, 53, 1) !important;
    
   
}

.submission-details{
    font-size: 14px !important;
font-family: $robotoFont !important;
font-weight: 400 !important;
color: rgba(105, 105, 105, 1) !important;
}
.sub-description{
    font-size: 16px !important;
font-family: $robotoFont !important;
font-weight: 400 !important;
color: rgba(53, 53, 53, 1) !important;
}

.submission-details-value {
    color: #8D8D8D !important;
    font-family: $robotoFont !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
}

.custom-ClientListingSearchbarContainer{
    @include max_width_devices($mui_md_breakpoint){
        margin-left: -8px !important;
      }
}

.custom-chip-fontsize{
    font-size: 14px !important;
}

.subFilterTab .MuiTabs-scrollButtonsHideMobile{
display: none;
}

.Mui-selected.filterTabNav:hover{
    color: #fff !important;
}