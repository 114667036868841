@import "./../../../assets/style/GlobalCSSVariables.scss";
@import "./../../../assets/style/GlobalSCSSFunctions.scss";

.EditIconJobAlert{
  margin-left: 12px !important;
}
.EditIconJobAlert svg{
  width: 20px !important;
}

.AlertLabelTitle{
  display: table-cell;
  min-width: 72px;
}

.jobtitle-text {
  font-family: $robotoFont;
  color: $primaryColor;
  display: table-cell;
}

.text-clr-black {
  color: #000;
}
.text-clr-primary {
  color: $primaryColor;
}
.border-clr {
  border-top: 1px solid #c4c4c4;
}
.minwidth-btn {
  min-width: 80px !important;
}

.Viewjobs-btn {
  font-size: 14px !important;
  font-family: $robotoFont !important;
  width: max-content;
}

.ManageJobAlert {
  color: $primaryColor !important;
  text-align: center;
  font-family: $robotoFont;
  font-size: 28px !important ;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
.txt-Alert-mt{
  margin-top: 9px !important;
}
.jobAlertRadiotype{
  margin-top: -7px;
}