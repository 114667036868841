@import "./../../assets/style/GlobalCSSVariables.scss";
@import "./../../assets/style/GlobalSCSSFunctions.scss";

.submisssionFiltertableToolbarCol {
  margin-top: 20px !important;
}

.filter-button-wrapper {
  padding: 16px 16px 24px;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 1199px) {
  .filter-button-wrapper {
    padding-top: 0 !important;
    flex-basis: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    justify-content: flex-end !important;
  }
}
@media (max-width: 479px) {
  .filter-button-wrapper {
    flex-direction: column !important;
    align-items: flex-end !important;
  }
  .filter-button-wrapper > div {
    margin: 0 0 10px !important;
  }
}

.pending-sub {
  padding-top: 23px;
}

.custom-Submit-btn-font {
  font-family: $robotoFont !important;
}

.tbl-namepadding{
  padding: 16px 16px 16px 9px !important;
}